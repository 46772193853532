import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { MailSenderService } from '../../services/mailSender.service';

@Component({
  selector: 'app-system112',
  templateUrl: './system112.component.html',
  styleUrls: ['./system112.component.scss']
})
export class System112Component implements OnInit, OnDestroy {

  fromName: string;
  fromEmail: string;
  text: string;
  isSubmitted: boolean;
  @ViewChild('email') email;
  private unsubscribe$ = new Subject();

  constructor(private mailService: MailSenderService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }


  goTo(url: string) {
    window.open(url, '_blank');
  }

  openPdf(url: string) {
    window.open(url);
  }

  sendEmail() {
    if (this.email.invalid) {
      this.email.control.markAsTouched();
      return;
    }
    const requestData = {
      name: this.fromName.trim(),
      contact: this.fromEmail.trim(),
      message: `\nЗапрос с сайта 112service: \n${this.text.trim()}`
    };
    this.mailService.sendMessage(requestData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fromEmail = undefined;
        this.text = undefined;
        this.fromName = undefined;
      })
    this.isSubmitted = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
