import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MailSenderService } from "../../services/mailSender.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {

  fromName: string;
  fromEmail: string;
  text: string;
  isSubmitted: boolean;
  @ViewChild('email') email;
  private unsubscribe$ = new Subject();

  constructor(private mailService: MailSenderService,
              private router: Router) { }

  ngOnInit(): void {
    this.scrollTop();
  }

  checkUrl() {
    const currUrl = this.router.url;
    this.router.navigate([currUrl + '#contacts']);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  sendEmail() {
    if (this.email.invalid) {
      this.email.control.markAsTouched();
      return;
    }
    const requestData = {
      name: this.fromName.trim(),
      contact: this.fromEmail.trim(),
      message: `\nЗапрос с сайта 112service:\n ${this.text.trim()}`
    };
    this.mailService.sendMessage(requestData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fromEmail = undefined;
        this.text = undefined;
        this.fromName = undefined;
      })
    this.isSubmitted = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
