<div class="footer">
    <div class="footer__wrapper wrapper">
        <div class="footer__items">
            <div class="footer__item">
                <div class="footer__item-content">
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-dscr">
                            Разработчик комплекса мобильных <br /> приложений Система–112:
                        </div>
                    </div>
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-img">
                            <a href="https://tetatech.ru">
                                <img src="assets/images/teta-white.svg"
                                     alt=""
                                     class="footer__item-content-img-pic">
                            </a>
                        </div>
                        <div class="footer__item-content-descriptor">
                          Информационно-<br />телекоммуникационная платформа
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__item">
                <div class="footer__item-content">
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-dscr">По вопросам технической поддержки:</div>
                    </div>
                  <div class="footer__item-content-email">
                    <a class="link" href="mailto:support112@tetatech.ru">support112@tetatech.ru</a>
                  </div>
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-descriptor">
                            <div class="footer__item-content-contact">
                                <a class="link" href="mailto:support112@tetatech.ru">support112@tetatech.ru</a>
                            </div>
                            <div class="footer__item-content-contact">
                                <a class="link" href="tel:+78005112269">+7 800 511-22-69</a>
                            </div>
                            <div class="footer__item-content-contact">
                              г Уфа, ул. Коммунистическая, д. 80, оф. 254
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
