import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MailSenderService } from '../../services/mailSender.service';

@Component({
  selector: 'app-social-monitoring',
  templateUrl: './social-monitoring.component.html',
  styleUrls: ['./social-monitoring.component.scss']
})
export class SocialMonitoringComponent implements OnInit, OnDestroy {

  fromName: string;
  fromEmail: string;
  text: string;
  isSubmitted: boolean;
  @ViewChild('email') email;
  private unsubscribe$ = new Subject();

  constructor(private mailService: MailSenderService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  goTo(url: string) {
    window.open(url, '_blank');
  }

  sendEmail() {
    if (this.email.invalid) {
      this.email.control.markAsTouched();
      return;
    }
    const requestData = {
      name: this.fromName.trim(),
      contact: this.fromEmail.trim(),
      message: `\nЗапрос с сайта 112service:\n ${this.text.trim()}`
    };
    this.mailService.sendMessage(requestData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.fromEmail = undefined;
          this.text = undefined;
          this.fromName = undefined;
        })
    this.isSubmitted = true;
  }

  openPdf(url: string) {
    window.open(url);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
