<header class="header"  [ngClass]="{'onScroll' : isSticky}">
    <div class="header__wrapper">
        <div class="header__logo">
            <a [routerLink]="['/']" class="header__logo-link">
                <img src="assets/images/logo-112.png" alt="" class="header__logo-img">
            </a>
        </div>
        <button class="header__menu-toggle"
                (click)="openMenu()"
                type="button">
            <ng-container *ngIf="!isOpenMenu">
                <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#0D0F14" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round">
                        <path d="M10 14h20M10 19.5h20M10 25h20"/>
                    </g>
                </svg>
            </ng-container>
            <ng-container *ngIf="isOpenMenu">
                <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#0D0F14" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round">
                        <path d="m11.515 11.015 16.97 16.97M11.515 27.986l16.97-16.971"/>
                    </g>
                </svg>
            </ng-container>
        </button>
        <div class="header__menu" [ngClass]="{'active' : isOpenMenu}">
            <div class="header__menu-items" #menu >
                <div class="header__menu-item"
                     [routerLinkActive]="'active'">
                    <a [routerLink]="['/system112']"
                       class="link">Система-112</a>
                </div>
                <div class="header__menu-item"
                     [routerLinkActive]="'active'">
                    <a [routerLink]="['/social-monitoring']" class="link">Социальный мониторинг</a>
                </div>
                <div class="header__menu-item"
                     [routerLinkActive]="'active'">
                    <a href="#developer" class="link">О разработчике</a>
                </div>
                <div class="header__menu-item"
                     [routerLinkActive]="'active'">
                    <a [href]="[this.url + '#contacts']"
                       [ngClass]="{'disable' : isChanged}"
                       class="link">Контакты</a>
                </div>
            </div>
            <div class="header__menu-contacts">
                <div class="header__menu-contact">
                    <a href="mailto:support112@tetatech.ru" class="link">support112@tetatech.ru</a>
                </div>
                <div class="header__menu-contact">
                    <a href="tel:+78123093921" class="link">+7 800 511-22-69</a>
                </div>
            </div>
        </div>
    </div>
</header>
