import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MailSenderService {

  constructor(private http: HttpClient) {
  }

  sendMessage(requestData) {
    let   url = 'https://new.octopod.com/api/feed_back';
    let headers = new HttpHeaders();
    headers = headers.append('X-CODE', 'bd16aaa7-a2e9-4f8d-ad92-356dfb80bcbe');
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, requestData, {headers});
  }

}
